import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChevronDown from "./chevron_down.svg"

class Chevron extends Component {
    constructor(props){
        super(props);
        this.state = {
            index:this.props.index,
            rotate:""
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.openIndex !== prevProps.openIndex){
            let rotate;
            if(this.props.openIndex === null || this.props.openIndex !== this.state.index){
                rotate = ""
            } else if(this.props.openIndex === this.state.index){
                rotate = "rotate"
            }
            this.setState({
                ...this.state,
                rotate:rotate
            })
        }
    }

    render() {
        return (
            <img src={ChevronDown} className={`chevron ${this.state.rotate}`}/>
        )
    }
    
}

Chevron.propTypes = {
    index:PropTypes.number.isRequired,
    openIndex: PropTypes.number.isRequired
}

export default Chevron;
