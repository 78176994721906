import React, {useState, useRef, useEffect} from "react";
import TaskAccordion from "../../Accordion/TaskAccordion"
import Accordion from "../../Accordion"
import TaskContainer from "./TaskContainer"
import {Container} from "./style"
import {createTaskDOM, createTagDOM, identifyTag} from "./utils"

export const AccordionTag = props => {
    const [showIndex, setShowIndex] = useState(0)
    const section = useRef(null);
    const { data } = props;
    const toggleShowIndex = index => {
        setShowIndex(index)
    }
    
    var tagDOMs = []
    var tasksDOMs = []

    const scrollToSection = () =>{
        window.scrollTo({
            top:section.current.offsetTop,
            behavior:"smooth"
        });
    }
    
    var tagColor = [] 

    const dummyFunc = () => {}

    useEffect(() => {
        setShowIndex(showIndex)
    })

    data.map((tag, index) => {
        if (tag.tasks_datas.length > 0){
            const tagList = createTaskDOM(tag)
            if(index === 0){
            tasksDOMs.push(
                    <TaskContainer index={index} showIndex={showIndex}>
                        <h2 className="tag__title">{tag.name} Tasks</h2>
                        <TaskAccordion data={tagList} toggleShowIndex={dummyFunc}/>
                    </TaskContainer>
                )            
            } else{
                tasksDOMs.push(
                    <TaskContainer index={index} showIndex={showIndex}>
                        <h2 className="tag__title">{tag.name} Tasks</h2>
                        <Accordion data={tagList}/>
                    </TaskContainer>
                )
            }

        } else{
                tasksDOMs.push(
                    <TaskContainer index={index} showIndex={showIndex}>
                        <h2 className="tag__title">{tag.name} Tasks</h2>
                        <p className="no__task">
                            You don&apos;t have any task yet.
                        </p>
                    </TaskContainer>
                )
            }
        tagDOMs.push(
            createTagDOM(tag.name, tag.id, index, scrollToSection)
            )
        tagColor.push(
            identifyTag(tag.id)
        )
        }
    )
    
    return(
        <Container>
                 <div className="tag__container">
                     <TaskAccordion data={tagDOMs} toggleShowIndex={toggleShowIndex}/>
                 </div>
                 <div ref={section} className={`tasks__container ${tagColor[showIndex]}`}>
                     {tasksDOMs}
                 </div>
        </Container>
    )
}
