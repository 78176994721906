/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import AccordionTitle from "../AccordionItem/AccordionTitle"
import AccordionContent from "../AccordionItem/AccordionContent"
import Chevron from "../AccordionItem/Chevron"
import { Container } from './style';

const Accordion = props => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = index => {
    if(openIndex === index){
      setOpenIndex(null)
    } else{
      setOpenIndex(index)
    }
  }
  
  return (
          <Container>
            {props.data && props.data.map((obj, index) => (
                <div className="accordion-container" key={index}>
                    <AccordionTitle
                      index={index}
                      buttonStyle={obj.headerStyle}
                      toggleAccordion={toggleAccordion}
                      openIndex={openIndex}
                    >
                      <div className="free__div">
                        {obj.header}
                      </div>
                      {/* TODO: CHILDREN DIV FOR HEADER */}
                      <Chevron 
                        index={index}
                        openIndex={openIndex}
                      />
                    </AccordionTitle>
                    <AccordionContent
                      index={index}
                      openIndex={openIndex}
                      contentStyle={{
                        width: "100%",
                        borderRadius: "0 0 10px 10px"
                      }}
                    >
                      <div className="free__div2">
                        {obj.content}
                      </div>
                      {/* TODO: CHILDREN DIV FOR CONTENT*/}
                    </AccordionContent>
                </div>
        ))}
      </Container>
  )
}

export default Accordion;
