import styled from "styled-components";

export const Container = styled.div`
/* Style the buttons that are used to open and close the accordion panel */
width: 100%;
.accordion-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.accordion {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  transition: border-radius 0.3s ease;
  position: relative;
}

.chevron{
  -webkit-transition: all .3s linear;
  -webkit-transform:rotate(0deg);
  position: absolute;
  right: 24px;
}

.rotate{
  -webkit-transition: all .3s linear;
  -webkit-transform:rotate(180deg);
}

.active {
    border-radius: 10px 10px 0px 0px !important;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  color:#01081F;
  width:100%;
  background-color: white;
  overflow-wrap:break-word;
  overflow:hidden;
  transition: max-height 0.3s ease;
}
`