import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { toastActions } from "../../../../redux/toast"
import { mbFormat, onDrop } from "./utils"
import { DropZoneContainer } from "./style"
import Button from "../../../Button"
import Cross from "../../../../../assets/images/dashboard-assets/crossBlack.svg"
import { useDispatch } from "react-redux"
import { updateTask } from "../../../../redux/slices/event/thunks"
import upload from "../../../../../assets/images/dashboard-assets/upload.svg"
import download from "../../../../../assets/images/dashboard-assets/download.svg"
import { getDeadline, identifyTag } from "../utils"

const UploadFile = (props) => {
  const dispatch = useDispatch()
  const {
    task,
    tasks,
    submitData,
    setSubmitData,
    warn,
    messages,
    initValues,
  } = props
  const [isEdit, setIsEdit] = useState(false)
  const [isSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const identification = identifyTag(task.eventId)
  const borderStyle =
    identification === "academy"
      ? { border: "4px dotted #0083FB" }
      : { border: "4px dotted #FB9661" }
  const buttonStyle =
    identification === "academy"
      ? { background: "linear-gradient(285.92deg,#2C98FC 0%,#0038FB 100%)" }
      : { background: "linear-gradient(95.79deg, #F12711 0%, #F5AF19 99.67%)" }
  const colorStyle = identification === "academy" ? "blue" : "orange"
  const bgStyle = identification === "academy" ? "sbmt-blue" : "sbmt-orange"
  const [file, setFile] = useState(() => {
    if (task.submission) {
      return {
        url: JSON.parse(task.submission.data),
        feedback: task.submission.feedback,
        feedbackAt: task.submission.feedback_at,
        isVerified: task.submission.is_verified,
        date: task.submission.updated_at,
      }
    }
    return undefined
  })

  const cekFeedback = () => {
    if (file.feedback !== null) {
      return true
    }
    return false
  }

  // TODO rapihin ini cukk
  const handleSubmit = (submitData, initValues) => {
    const fields = {}
    const attachments = {}
    tasks.forEach((field) => {
      const data = field.data
      if (
        field.type === "text" &&
        submitData[field.id] !== initValues[field.id]
      ) {
        fields[field.id] = submitData[field.id] || ""
      } else if (field.type === "multiple_choice" && data.max_choice === 1) {
        if (
          submitData[field.id] &&
          submitData[field.id] !== initValues[field.id]
        ) {
          fields[field.id] = [submitData[field.id]]
        }
      } else if (field.type === "multiple_choice" && data.max_choice !== 1) {
        if (
          submitData[field.id] &&
          submitData[field.id] !== initValues[field.id]
        ) {
          fields[field.id] = submitData[field.id]
        }
      } else if (field.type === "file_upload" || field.type === "invoice") {
        if (submitData[field.id]) {
          attachments[field.id] = submitData[field.id]
        }
      }
    })

    setIsLoading(true)
    dispatch(
      updateTask(
        task.eventId,
        task.teamId,
        task.taskId,
        fields,
        attachments,
        () => {}
      )
    )
      .then((res) => {
        const data = res.data.fields.filter((field) => {
          return field.id === task.id
        })[0].submission
        setFile({
          url: JSON.parse(data.data),
          date: data.updated_at,
        })
        setIsEdit(false)
        setIsLoading(false)
        setSubmitData({ ...submitData, [task.id]: null })
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  return (
    <Dropzone
      accept={task.data.extensions.join(", ")}
      maxSize={task.data.max_size * 1024}
      multiple={false}
      onDrop={(acceptedFiles, rejectedFiles) =>
        onDrop(
          acceptedFiles,
          rejectedFiles,
          task,
          submitData,
          setSubmitData,
          setIsEdit,
          warn,
          messages,
          task.data.extensions.join("/")
        )
      }
    >
      {({ getRootProps, getInputProps }) => (
        <DropZoneContainer height={file && cekFeedback() ? "500px" : "250px"}>
          <div
            style={{
              width: "100%",
              height: `${file && cekFeedback() ? "55%" : "80%"}`,
            }}
          >
            <div className="dropzone-container">
              <div {...getRootProps()} className="dropzone" style={borderStyle}>
                <input {...getInputProps()} onClick={() => setIsEdit(true)} />
                {!file && !submitData[task.id] && (
                  <>
                    <Button style={buttonStyle} className="acad-btn">
                      <img src={upload} alt="upload" />
                      Upload Files
                    </Button>
                    <span>
                      MAX Size: {mbFormat(task.data.max_size * 1024)}MB
                    </span>
                  </>
                )}
                {file && isEdit && !submitData[task.id] && (
                  <>
                    <Button style={buttonStyle} className="acad-btn">
                      <img src={upload} alt="upload" />
                      Upload Files
                    </Button>
                    <span>
                      MAX Size: {mbFormat(task.data.max_size * 1024)}MB
                    </span>
                  </>
                )}
                {!file && submitData[task.id] && (
                  <>
                    <span style={{ color: "white", width: "100%" }}>
                      File Name: {submitData[task.id].name}
                    </span>
                  </>
                )}
                {file && !isEdit && (
                  <div className={`submitted-task ${bgStyle}`}>
                    <span>Task submitted:</span>
                    <span>{getDeadline(file.date)}</span>
                  </div>
                )}
                {file && isEdit && submitData[task.id] && (
                  <>
                    <span style={{ color: "white", width: "100%" }}>
                      File Name: {submitData[task.id].name}
                    </span>
                  </>
                )}
              </div>
              {isEdit && (
                <img
                  className="float-btn"
                  src={Cross}
                  onClick={() => {
                    setIsEdit(!isEdit)
                    setSubmitData({ ...submitData, [task.id]: null })
                  }}
                />
              )}
              {file && isEdit && submitData[task.id] && !isSuccess && (
                <div className="submit__div">
                  <span className="file__name">
                    File Name: {submitData[task.id].name}
                  </span>
                  <Button
                    style={buttonStyle}
                    className={` submit__btn ${isLoading && "extra-left"}`}
                    onClick={() => handleSubmit(submitData, initValues)}
                  >
                    {isLoading ? "Submitting" : "Submit"}
                  </Button>
                </div>
              )}
              {!file && submitData[task.id] && !isSuccess && (
                <div className="submit__div">
                  <span className="file__name">
                    File Name: {submitData[task.id].name}
                  </span>
                  <Button
                    style={buttonStyle}
                    className={` submit__btn ${isLoading && "extra-left"}`}
                    onClick={() => handleSubmit(submitData, initValues)}
                  >
                    {isLoading ? "Submitting" : "Submit"}
                  </Button>
                </div>
              )}
            </div>
            {file && (
              <div className="btn-container">
                <Button
                  style={buttonStyle}
                  className="acad-btn"
                  href={file.url}
                >
                  <img src={download} alt="download" />
                  <span>Download Submission</span>
                </Button>
              </div>
            )}
            {file && cekFeedback() && (
              <div className={`feedback-container ${colorStyle}`}>
                <h3 className={colorStyle}>Comment & Feedback</h3>
                <div id="line" className={colorStyle} />
                <div className={`feedback ${colorStyle}`}>
                  <div>
                    <p>Posted on: {getDeadline(file.feedbackAt)}</p>
                    {file.feedback !== "" ? (
                      <p>{file.feedback}</p>
                    ) : (
                      <p>Your task is already verified</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </DropZoneContainer>
      )}
    </Dropzone>
  )
}

const Submission = (props) => {
  const { task, field } = props

  const warn = toastActions.showToast
  const messages = {
    fileTooLarge: "File is to large",
    mutipleFiles: "You can only upload 1 file!",
    default: "Only 1 file can be uploaded",
  }

  const tmpSubmitData = {}
  const initValues = {}
  task.map((field) => {
    const data = field.data

    const { submission } = field
    let initValue = null
    if (submission) {
      if (field.type === "text") {
        initValue = JSON.parse(submission.data)
      } else if (field.type === "multiple_choice" && data.max_choice === 1) {
        initValue = JSON.parse(submission.data)[0]
      } else if (field.type === "multiple_choice" && data.max_choice !== 1) {
        initValue = JSON.parse(submission.data)
      }
    }

    tmpSubmitData[field.id] = initValue
    initValues[field.id] = initValue
  })

  const [submitData, setSubmitData] = useState(tmpSubmitData)

  if (field.type === "file_upload") {
    return (
      <UploadFile
        tasks={task}
        task={field}
        submitData={submitData}
        setSubmitData={setSubmitData}
        initValues={initValues}
        warn={warn}
        messages={messages}
      />
    )
  }
  return <h1>Text</h1>
}

export default Submission
