import React, { Component } from 'react'
import PropTypes from "prop-types"

class AccordionContent extends Component{
    constructor(props){
        super(props);
        this.content = React.createRef()
        this.state = {
            index:this.props.index,
            height:"0px"
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.openIndex !== prevProps.openIndex){
            let height;
            if (this.props.openIndex === null || this.props.openIndex !== this.state.index){
                height = "0px"
            } else if(this.props.openIndex === this.state.index){
                height=`${this.content.current.scrollHeight}px`
            }
            this.setState({
            ...this.state,
            height:height
            })
        }
    }

    render(){
        const { contentStyle } = this.props;
        return(
            <div ref={this.content} style={{
                ...contentStyle,
                maxHeight:this.state.height
            }} className="accordion__content">
                {this.props.children}
            </div>
        )
    }
}

AccordionContent.propTypes = {
    index: PropTypes.number.isRequired,
    openIndex:PropTypes.number.isRequired
}

export default AccordionContent;
