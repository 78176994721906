import React, {useState, useEffect} from "react";
import {cleanTeamData} from "./utils"
import { AccordionTag } from "./AccordionTag"
import { useSelector } from "react-redux";
import { getAuthUser } from "../../../redux/slices/session/selectors";
import { Container } from "./style"
import Button from "../../Button"
// import {TESTDATA, TESTDATA1, TESTDATA2} from "./testcases"
import {TESTDATA3} from './testcases'

const Task = () => {

    const {teams,email,tags, events} = useSelector(state => ({
        teams:state.event.teams,
        events: state.event.events,
        email: getAuthUser(state).usable_email,
        tags:state.event.tags,
    }))
    const [task,setTask] = useState([])

    // filter teams and events to exclude seminars // BUGGY
    // let newEvents = {}
    // let newTeams = {}
    
    // if (events) {
    //     for (const [key, value] of Object.entries(events)) {
    //         const year = (new Date(value.start_at)).getYear() + 1900
    //         if (!value.ticketable && year >= 2020) {
    //             newEvents[key] = value
    //         }
    //     }
    // }

    // if (teams) {
    //     for (const [key, value] of Object.entries(teams)) {
    //         if (value.registerable_event in newEvents) {
    //             newTeams[key] = value
    //         }
    //     }
    // }
    // --- BUGGY

    // Use this to test using testcases
    useEffect(() => {
        setTask(cleanTeamData(teams, events, email))
    }, [teams])

    if (task && task.length > 0) {
        return (
            <AccordionTag data={task} />
        )
    }   
    else {
        return (
            <Container>
                <div className="tag__container">
                    <div className="no__event">
                        <h2 className="no__event__title">
                            You have no event registered
                        </h2>
                        <div className="btn">
                        <Button className="no__event__button" to="/welcome">
                            Find Event
                        </Button>
                        </div>
                    </div>
                </div>
                <div className="tasks__container academy">
                    <h2 className="tag__title">Tasks</h2>
                    <p className="no__task">
                        You don&apos;t have any task yet
                    </p>
                </div>
            </Container>
        )
    }

}
export default Task;