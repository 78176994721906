// TASK TESTCASES
export const TESTDATA = [
    {
        event:"data-science",
        id:0,
        name:"Data Science",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[
            {
                end:"8 September 2019",
                start:"27 Agustus 2019",
                task:[
                    {
                        data:{
                            description:"Tuliskan motivasi anda untuk mengikuti Akademi ini (minimal 500 kata)",
                            helper:"",
                            max_chars:0,
                            multiline:true,
                            regex:".*",
                            title:"Task 1: Submission ini ini ini ini",

                        }
                    }
                ]
            }
        ]
    },
    {
        event:"startup",
        id:1,
        name:"Startup",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[
            {
                end:"8 September 2019",
                start:"27 Agustus 2019",
                task:[
                    {
                        data:{
                            description:
                            {
                                content:"Tuliskan motivasi anda untuk mengikuti Akademi ini (minimal 500 kata)",
                                links:[
                                    {
                                        description:"Attachment for drive",
                                        link:"https://drive.google.com"
                                    },
                                    {
                                        description:"Attachment for facebook page",
                                        link:"https://facebook.com"
                                    },
                                    {
                                        description:"Google",
                                        link:"https://google.com"
                                    }
                                ]
                            },
                            helper:"",
                            max_chars:0,
                            multiline:true,
                            regex:".*",
                            title:"Task 2: Submission ini ono no onono",
                        }
                    }
                ]
            }
        ]
    },
    {
        event:"software-engineering",
        id:0,
        name:"Software Engineering",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[
            {
                end:"8 September 2019",
                start:"27 Agustus 2019",
                task:[
                    {
                        data:{
                            description:
                            {
                                content:"Tuliskan motivasi anda untuk mengikuti Akademi ini (minimal 500 kata)",
                                links:[
                                    {
                                        description:"Attachment for drive",
                                        link:"https://drive.google.com"
                                    },
                                    {
                                        description:"Attachment for facebook page",
                                        link:"https://facebook.com"
                                    },
                                    {
                                        description:"Google",
                                        link:"https://google.com"
                                    }
                                ]
                            },
                            helper:"",
                            max_chars:0,
                            multiline:true,
                            regex:".*",
                            title:"Task 3: nganu gonongongon",
                        }
                    }
                ]
            }
        ]
    },
    {
        event:"user-experience",
        id:0,
        name:"User Experience",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[
            {
                end:"8 September 2019",
                start:"27 Agustus 2019",
                task:[
                    {
                        data:{
                            description:
                            {
                                content:"Tuliskan motivasi anda untuk mengikuti Akademi ini (minimal 500 kata)",
                                links:[
                                    {
                                        description:"Attachment for drive",
                                        link:"https://drive.google.com"
                                    },
                                    {
                                        description:"Attachment for facebook page",
                                        link:"https://facebook.com"
                                    },
                                    {
                                        description:"Google",
                                        link:"https://google.com"
                                    }
                                ]
                            },
                            helper:"",
                            max_chars:0,
                            multiline:true,
                            regex:".*",
                            title:"Task 3: nganu gonongongon",
                        }
                    }
                ]
            }
        ]
    }
]

export const TESTDATA1 = [
    // A.K.A. no events
]
export const TESTDATA2 = [
    {
        event:"data-science",
        id:0,
        name:"Data Science",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[]
    },
    {
        event:"startup",
        id:1,
        name:"Startup",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[
            {
                end:"8 September 2019",
                start:"27 Agustus 2019",
                task:[
                    {
                        data:{
                            description:"Tuliskan motivasi anda untuk mengikuti Akademi ini (minimal 500 kata)",
                            helper:"",
                            max_chars:0,
                            multiline:true,
                            regex:".*",
                            title:"Task 3: nganu gonongongon",
                            links:[
                                {
                                    description:"Attachment for drive",
                                    link:"https://drive.google.com"
                                },
                                {
                                    description:"Attachment for facebook page",
                                    link:"https://facebook.com"
                                },
                                {
                                    description:"Google",
                                    link:"https://google.com"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        event:"software-engineering",
        id:0,
        name:"Software Engineering",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[
            {
                end:"8 September 2019",
                start:"27 Agustus 2019",
                task:[
                    {
                        data:{
                            description:"Tuliskan motivasi anda untuk mengikuti Akademi ini (minimal 500 kata)",
                            helper:"",
                            max_chars:0,
                            multiline:true,
                            regex:".*",
                            title:"Task 3: nganu gonongongon",
                            links:[
                                {
                                    description:"Attachment for drive",
                                    link:"https://drive.google.com"
                                },
                                {
                                    description:"Attachment for facebook page",
                                    link:"https://facebook.com"
                                },
                                {
                                    description:"Google",
                                    link:"https://google.com"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        event:"user-experience",
        id:0,
        name:"User Experience",
        tag:"academy",
        task_deadline:"6 Februari 2020",
        tasks_datas:[
            // A.K.A. no task
        ]
    }

]


JSON.stringify({
    content: "ini nge test klo ada link dan kontent yaa",
    links:[
                {
                    description:"Attachment for drive",
                    link:"https://drive.google.com"
                },
                {
                    description:"Attachment for facebook page",
                    link:"https://facebook.com"
                },
                {
                    description:"Google",
                    link:"https://google.com"
                }
            ]
})


export const example = '{"content":"ini nge test klo ada link dan kontent yaa","links":[{"description":"Attachment for drive","link":"https://drive.google.com"},{"description":"Attachment for facebook page","link":"https://facebook.com"},{"description":"Google","link":"https://google.com"}]}'
const events = {
  startup: 60,
  sea: 62,
  dsa: 61,
  "bizz-it": 69,
  scpc: 65,
  jcpc: 64,
  ctf: 63,
  iacp: 68,
  iacs: 66,
  igi: 67,
  "mini-case": 70,
  playground: 71,
  uxa: 90,
}

const dummyJSON = {
    "content":"Upload your newest photo in accordance with the provisions that have been included in the guidelines.",
    "links":[{"description":"Guideline","link":"https://drive.google.com/file/d/1851pGa5-x5J2aKvmvmZWMlJTv_tN26k6/view?usp=sharing"}]
}
export const TESTDATA3 = [
    {
        event:'cf-12-senior-competitive-programming',
        id:65,
        isLeader:true,
        name:"Senior Competitive Programming",
        task_deadline:'19 July 2020 at 23:59 WIB',
        tasks_datas:[
            {
                start:"29 June 2020 at 16:00 WIB",
                end:'19 July 2020 at 23:59 WIB',
                task:[
                    {
                        data:{
                            title:"Photo", 
                            description:"'" +JSON.stringify(dummyJSON) + "'",
                            extensions:['.png','.jpg','.jpeg','.pdf'],
                            helper:'',
                            max_size:10240,
                        },
                        eventId:65,
                        id:152,
                        is_leader_only:false,
                        remaining_verified_count:null,
                        submission:{
                            created_at:"2020-07-02T14:55:45.529601+07:00",
                            data:'"https://zeus-dev-bucket.s3.amazonaws.com/media/GRAND-LAUNCHING/Mario%20Serano%20%3Cmarioserano55%40gmail.com%3E-marioserano55%40gmail.com/24828fad-555d-4f8d-9c39-015704772838-6120-9a-e103661ce506f3b882b4.jpg"',
                            feedback:null,
                            feedback_at:null,
                            is_verified:false,
                            updated_at:"2020-07-02T14:55:45.529601+07:00"
                        },
                        taskId:90,
                        teamId:30000,
                        type:'file_upload'
                    }
                ],
                
            }
        ]
    },
    {
        event:'cf-12-software-engineering-academy',
        id:62,
        isLeader:true,
        name:"Software Engineering Academy",
        task_deadline:'19 July 2020 at 23:59 WIB',
        tasks_datas:[
            {
                start:"29 June 2020 at 16:00 WIB",
                end:'19 July 2020 at 23:59 WIB',
                task:[
                    {
                        data:{
                            title:"Photo", 
                            description:"'" + JSON.stringify(dummyJSON) + "'",
                            extensions:['.png','.jpg','.jpeg','.pdf'],
                            helper:'',
                            max_size:10240,

                        },
                        eventId:62,
                        id:152,
                        is_leader_only:false,
                        remaining_verified_count:null,
                        submission:{
                            created_at:"2020-07-02T14:55:45.529601+07:00",
                            data:'"https://zeus-dev-bucket.s3.amazonaws.com/media/GRAND-LAUNCHING/Mario%20Serano%20%3Cmarioserano55%40gmail.com%3E-marioserano55%40gmail.com/24828fad-555d-4f8d-9c39-015704772838-6120-9a-e103661ce506f3b882b4.jpg"',
                            feedback:null,
                            feedback_at:null,
                            is_verified:false,
                            updated_at:"2020-07-02T14:55:45.529601+07:00"
                        },
                        taskId:90,
                        teamId:30000,
                        type:'file_upload'
                    }
                ],
                
            }
        ]
    },
    {
        event:'cf-12-bizz-it',
        id:69,
        isLeader:true,
        name:"Bizz-IT Competition",
        task_deadline:'19 July 2020 at 23:59 WIB',
        tasks_datas:[
            {
                start:"29 June 2020 at 16:00 WIB",
                end:'19 July 2020 at 23:59 WIB',
                task:[
                    {
                        data:{
                            title:"Photo", 
                            description:"'" + JSON.stringify(dummyJSON) + "'",
                            extensions:['.png','.jpg','.jpeg','.pdf'],
                            helper:'',
                            max_size:10240,
                        },
                        eventId:69,
                        id:152,
                        is_leader_only:false,
                        remaining_verified_count:null,
                        submission:{
                            created_at:"2020-07-02T14:55:45.529601+07:00",
                            data:'"https://zeus-dev-bucket.s3.amazonaws.com/media/GRAND-LAUNCHING/Mario%20Serano%20%3Cmarioserano55%40gmail.com%3E-marioserano55%40gmail.com/24828fad-555d-4f8d-9c39-015704772838-6120-9a-e103661ce506f3b882b4.jpg"',
                            feedback:null,
                            feedback_at:null,
                            is_verified:false,
                            updated_at:"2020-07-02T14:55:45.529601+07:00"
                        },
                        taskId:90,
                        teamId:30000,
                        type:'file_upload'
                    }
                ],
            }
        ]
    }
]