import React from "react"
import attachment from "./imgs/attachment.svg"
import Button from "../../Button"
import Submission from "./submission/"
import { toastActions } from "../../../redux/toast"

const headerStyle = background => {
    return{
        background:background,
        color:"#F3F3F3",
        borderRadius:"10px",
        padding:"24px",
    }
}


// Works
export const cleanTeamData = (teams, events, email) => {

    if(!(teams === undefined || teams === null)){
        const tasks_clean = Object.keys(teams).map((key) => {
            const task_deadline = teams[key].task_deadline
            const event = teams[key].registerable_event
            const tasks_raw = teams[key].tasks
            const isLeader = teams[key].members.filter(member => {
                return member.is_leader && member.account.usable_email === email
            }).length > 0;

            const tasks_datas = Object.keys(tasks_raw).map((key1) => {
                const task = tasks_raw[key1]
                if(!(task.fields === undefined || task.fields === null)){
                    return {
                        start: getDeadline(task.start_at),
                        end: getDeadline(task.end_at),
                        task: task.fields.map(t => {
                            return {
                                data: JSON.parse(t.data),
                                eventId: events[event].id,
                                teamId: teams[key].id,
                                id: t.id,
                                taskId: task.id,
                                is_leader_only: t.is_leader_only,
                                remaining_verified_count: t.remaining_verified_count,
                                submission: t.submission,
                                type: t.type,
                            }
                        }),
                        title: task.title,
                        type: task.type

                    }
                }
            })
            return {
                name: events[teams[key].registerable_event].name,
                id:events[event].id,
                isLeader,
                // tag:tag,
                event:event,
                task_deadline:getDeadline(task_deadline),
                tasks_datas:tasks_datas
            }
        })
        return tasks_clean
    }
    return []
}

// Works
export const getDeadline = taskDeadline => {
    var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", " Desember"
    ]
    if(!(taskDeadline === undefined || taskDeadline === null)){
        const deadline = new Date(Date.parse(taskDeadline))
        const month = deadline.getMonth();
        const date = deadline.getDate();
        const year = deadline.getFullYear();
        let hour = deadline.getHours();
        let minute = deadline.getMinutes();
        if (hour === 0) {
            hour =  "00"
        }
        if (minute === 0) {
            minute = "00"
        }
        return `${date} ${monthNames[month]} ${year} at ${hour}:${minute} WIB`
    }
}

const mergeArray = data => {
    let res = []
    for (let i = 0; i < data.length; i += 1) {
        res = res.concat(data[i])
    }
    return res;
}

const createLinks = links => {
    if(!(links === undefined || links === null)){
        var domLinks = []
        links.map(link => {
            domLinks.push(
                <a href={link.link}>
                    <img src={attachment} />
                    <div className="link">
                        {link.description}
                    </div>
                </a>
            )
        })
        return domLinks
    }
    return []
}

const processJSON = data => {
    try {
        const res = JSON.parse(data.slice(1,-1))
        return res;
    }
    catch {
        return data;
    }
}
export const identifyTag = (tagId) => {
    const events = {
        startup: 60, // academy
        sea: 62, // academy
        dsa: 61, // academy
        "bizz-it": 69, // competition
        scpc: 65, // competition
        jcpc: 64, // competition
        ctf: 63, // competition
        iacp: 68, 
        iacs: 66,
        igi: 67, // competition
        "mini-case": 70, // competition
        playground: 71,
        uxa: 90, // academy
    }
    if(tagId === 60 || tagId === 61 || tagId === 62 || tagId === 90 ){
        return 'academy'
    } else if(tagId === 69 || tagId === 63 || tagId === 70 || tagId === 65 || tagId === 64 || tagId === 67){
        return 'competition'
    } else{
        return undefined
    }
}

export const createTaskDOM = (tag) => {

    const res =  tag.tasks_datas.map(task => { return {
        deadline: task.end,
        task: task.task
    } }).map(t => { return t.task.map(a => {
        return {
            header:(<div className="task__title"><h2 className="task__titling">{a.data.title}</h2><p className="task__deadline">{t.deadline}</p></div>),
            content:(
            <div className="description">
                {processJSON(a.data.description).content && 
                <>
                    <p>
                        {processJSON(a.data.description).content}
                    </p>
                    <div className="links__container">
                        {createLinks(processJSON(a.data.description).links)}
                    </div>
                </>
                }
                {!processJSON(a.data.description).content && 
                <>
                    <p>
                        {a.data.description}
                    </p>
                </>
                }
                <div className="upload__container">
                    <Submission deadline={t.deadline}  task={t.task} field={a} />
                </div>
            </div>),
            headerStyle:identifyTag(tag.id) === 'academy' ? headerStyle("linear-gradient(285.73deg, #44C3DB 0%, #0083FB 100%)") : headerStyle("linear-gradient(94.23deg, #EC544F 0%, #EE965F 100%)")
        }
    })})

    if (res.length > 1) {
        return mergeArray(res)
    }
    return res[0];
}

export const createTagDOM = (tag, id, index, func) => {
    const identification = identifyTag(id)
    const contentTag = identification === 'academy' ? "Link to Slack for" : "Link to Material for"
    const domTag = identification === 'academy' ? (<p className="tag">{tag}</p>) : (<p className="tag">Registration</p>)
    const linkBtnStyle = identification === 'academy' ? {background:'linear-gradient(277.54deg, #2C98FC 0%, #0038FB 100%)'} : {background:'linear-gradient(94.07deg, #F12711 0%, #F5AF19 99.67%)'}
    const colorStyle = identification === 'academy' ? "blue" : "orange"
    return {
        header:<div className="top-acc"><h2>{tag}</h2></div>,
        content:(
        <div className="bot-acc">
            <div className="link-tag">
                <p className='slack-tag'>{`${contentTag}`}</p>
                {domTag}
            </div>
            <Button
              className="link-btn"
              style={linkBtnStyle}
              onClick={() => toastActions.showToast("Coming Soon!")}
            >
              Visit Now
            </Button>
            <div className="task__detail__border">
                <div className="task__detail__wrapper">
                    <Button onClick={() => func()} className={`task__detail ${colorStyle}`}>Task Detail</Button>
                </div>
            </div>
        </div>),
        showIndex:index,
        headerStyle:identification === 'academy' ? headerStyle("linear-gradient(285.73deg, #44C3DB 0%, #0083FB 100%)") : headerStyle("linear-gradient(94.23deg, #EC544F 0%, #EE965F 100%)")
    }
}
