export const cekSubmission = (task) => {
  const tmpSubmitData = {}
  const initValues = {}
  task.map((field) => {
    const data = JSON.parse(field.data)

    const { submission } = field
    let initValue = null
    if (submission) {
      if (field.type === "text") {
        initValue = JSON.parse(submission.data)
      } else if (field.type === "multiple_choice" && data.max_choice === 1) {
        initValue = JSON.parse(submission.data)[0]
      } else if (field.type === "multiple_choice" && data.max_choice !== 1) {
        initValue = JSON.parse(submission.data)
      }
    }
    tmpSubmitData[field.id] = initValue
    initValues[field.id] = initValue
  })
  return [tmpSubmitData, initValues]
}

export const mbFormat = (byteString, separator = ".") => {
  const res = String(byteString)
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${separator}`)
    .split(".")
  return res[0]
}

const onUpload = (file, field, submitData, setSubmitData) => {
  setSubmitData({ ...submitData, [field.id]: file })
}

export const onDrop = (
  acceptedFiles,
  rejectedFiles,
  field,
  submitData,
  setSubmitData,
  setIsEdit,
  warn,
  messages,
  accept
) => {
  if (rejectedFiles.length > 1) {
    warn(messages.mutipleFiles)
    // alert("more than one file is not allowed")
    return
  }
  if (rejectedFiles.length === 1) {
    if (rejectedFiles[0].size > field.data.max_size * 1024) {
      warn(messages.fileTooLarge)
      // alert("file to large")
      return
    }
    warn(`File type must be ${accept}`)
    return
  }
  if (acceptedFiles[0]) {
    onUpload(acceptedFiles[0], field, submitData, setSubmitData)
    setIsEdit(true)
  }
}
