import React, { Component } from 'react'
import PropTypes from "prop-types"

class AccordionTitle extends Component {
    constructor(props){
        super(props);
        this.state={
            index:this.props.index,
            active:""
        }
    }
    componentDidUpdate(prevProps){
        if(this.props.openIndex !== prevProps.openIndex){
            let active;
            if(this.props.openIndex === null || this.props.openIndex !== this.state.index){
                active = ""
            } else if(this.props.openIndex === this.state.index){
                active = "active"
            } 
            this.setState({
                ...this.state,
                active:active
            })
        }
    }
    render() {
        const {active} = this.state
        const {toggleAccordion, index, buttonStyle} = this.props
        return (
            <button 
                className={`accordion ${active}`}
                onClick={() => toggleAccordion(index)}
                index={index}
                style={buttonStyle}
            >
                {this.props.children}
            </button>
        )
    }
}

AccordionTitle.propTypes = {
    toggleAccordion:PropTypes.func.isRequired,
    index:PropTypes.number.isRequired,
    openIndex:PropTypes.number.isRequired,
    buttonStyle:PropTypes.object.isRequired
}

export default AccordionTitle;