import styled from "styled-components";

export const DropZoneContainer = styled.section`
    height: ${props => props.height};
    width: 100%;
    @media(max-width:768px){
        height: ${props => props.height === "500px" ? "570px" : "400px"};
    }

    .dropzone-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;        
        position: relative;
    }
    
    .dropzone {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius:10px;
        box-sizing:border-box;

        span {
            text-align: center;
        }
    }
    
    .float-btn {
        position: absolute;
        z-index: 5;
        top: 10px;
        right: 10px;
    }

    .submitted-task {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;

    }
    .sbmt-orange {
        background: #ffb23e30;
    }

    .sbmt-blue {
        background: rgba(44, 152, 252, 0.2);
    }
    
    .submit {
        position:absolute;
        display:flex;
        flex-direction:column;
        left: 40%;
        --webkit-transform: translate(-50%,-20%);
        top: 20%;
    }
    .file__name{
        text-align:center;
        width:100%;
    }
    .submit__btn{
        background: linear-gradient(285.92deg, #2C98FC 0%, #0038FB 100%);
    }
    .submit__div{
        margin-top:-120px;
        display:flex;
        flex-direction:column;
        align-items:center;
    }

    .t80 {
        top: 80%;
    }

    .btn-container {
        margin-top: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    
    .acad-btn {
        display:flex;
        margin-bottom: 16px;
        justify-content: space-evenly;
        align-items: center;

        img {
            margin-right: 8px;
        }
    }

    .orange {
        color: #FF5F2F;

        div {
            border-color: #FF5F2F !important;
        }
    }

    .blue {
        color: #0083FB;

        div {
            border-color: #0083FB !important;
        }   
    }

    .feedback-container {
        widht: 100%;
        height: 100px;
        border: none !important;

        h3 {
            border: none !important;
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            margin: 0;
        }

        #line {
            widht: 100%;
            border: 1px solid black;
            margin: 0 0 8px;
        }

        .feedback {
            width: 100%;
            height: 100%%;
            border: 1px solid black;
            border-radius: 8px;

            div {
                padding: 12px;
            }

            div p:first-child {
                text-align: left;
                font-weight: bold;
            }

            p {
                padding: 0;
                margin: 4px;
            }
        }
    }

    @media (max-width:768px){
        .submit__div{
            margin-top:-230px;
        }
    }
`