import React, { Component } from 'react'
import PropTypes from 'prop-types'

class TaskContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index:this.props.index,
            active:""
        }
    }
    
    componentDidUpdate(prevProps){
        if(this.props.showIndex !== prevProps.showIndex){
            let active;
            if(this.props.showIndex === null || this.props.showIndex !== this.state.index){
                active = ""
            } else if(this.props.showIndex === this.state.index){
                active = "show"
            }
            this.setState({
                ...this.state,
                active:active
            })
        }
    }
    componentDidMount(){
        if(this.props.index === this.props.showIndex){
            let active = "show"
            this.setState({
                ...this.state,
                active:active
            })
        }
    }

    render() {
        return (
            <div className={`task ${this.state.active}`}>
                {this.props.children}
            </div>
        )
    }
}   

TaskContainer.propTypes = { 
    index:PropTypes.number.isRequired,
    showIndex:PropTypes.number.isRequired
}

export default TaskContainer;