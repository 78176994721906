import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DefaultDashboardPage from "../../components/DashboardPage/index-dashboard"
import DashboardTask from "../../components/DashboardPage/DashboardTask"

// const data = [
//   {
//     header:"Data Science Academy",
//     content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//   },
//   {
//     header:"Software Engineering Academy",
//     content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//   }
// ]

const TaskPage = () => (
  <Layout>
    <SEO title="Task" />
    <DefaultDashboardPage>
      <DashboardTask />
    </DefaultDashboardPage>
  </Layout>
)

export default TaskPage
