import styled from 'styled-components'

export const Container = styled.div`
    display:flex;
    font-size:16px;

    .task{
        display:none;
        width:100%;
    }
    h2{
        font-family:itc, sans-serif;
        font-style:normal;
        font-weight:bold;
    }
    .show{
        display:flex;
        flex-direction:column;
    }
    .free__div{
        width:100%;
    }
    .tag__container{
        width:28%;
    }   
    .accordion-container{
        margin-bottom:20px;
    }
    .top-acc{   
        width:80%;
        text-align:left;
        h2{
            font-size:1.35em;
            margin:0;
            font-family: itc, sans-serif;
            font-style:normal;
            font-weight:500;
        }       
    }
    .link-btn{
        position:absolute;
        right:24px;
        top:24px;
        width:118px;
    }
    
    .tag__title{
        width:80%;
        margin:0 auto;
        text-align:center;
        margin-bottom:20px;
    }
    .tasks__container{
        margin-left: 32px;
        width: 60%;   
        box-sizing: border-box;
        border-radius: 10px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .academy{
       border: 4px solid #0083FB;
    }
    .competition{
        border: 4px solid #FB9661;
    }
    .bot-acc{
        display:flex;
        padding:24px;
        position:relative;
        flex-direction:column;
    }
    .task__title{
        display:flex;
        width:90%;
        position:relative;
        h2{
            font-size:1.35em;
            margin:0;
            font-family: itc, sans-serif;
            font-style:normal;
            font-weight:500;
        }       
    }
    .task__detail{
        width:100%;
        width:100%;
        font-family:DM Sans, sans-serif;
        font-weight:bold;

        // .orange {
        //     background: #F5AF19;
        // }
    }
    .task__detail__border, .task__detail__wrapper, .task__detail{
        display:none;
    }
    .task__detail__wrapper{
        background:white;
        border-radius:100px;
    }
    .task__detail__border{
        // background:linear-gradient(286.81deg, #2C98FC 0%, #0038FB 100%);
        padding:3px;
        border-radius:100px;
        margin-top:20px;

    }
    
    .orange {
        border: 2px solid #F5AF19;
        color: #F5AF19;
        background: white;
    }

    .blue {
        border: 2px solid #0038FB;
        color: #0038FB;
        background: white;
    }
    .no__task{
        text-align:center;
        font-size:14px;
        font-style:normal;
        font-weight:normal;
        color:#F3F3F3;
    }
    .no__event__button{
        font-family:DM Sans, sans-serif;
        font-size:14px;
        background:linear-gradient(289.62deg, #2C98FC 0%, #0038FB 100%);
    }
    .description{
        padding:24px 32px;
        p:first-child{
            margin-top:0;
            margin-bottom:16px;
        }
        p{
            margin:16px 0;
            text-align:justify;
        }
    }
    
    .upload__container{

        p{
            padding:100px 0;
        }
        p:first-child{
            text-align:center;
            margin-bottom:0;
        }
    }
    .task__deadline{
        position:absolute;
        right:0;
        top:5px;
    }
    .task__titling{
        width:70%;
        word-break:break-word;
        text-align:justify;
    }
    .link-tag{
        width:120px;
    }
    .slack-tag{
        width:100%;
    }
    .tag{
        width:90%;
        .competition{
            display:none;
        }
    }
    .no__event__title{
        margin-top:0;
        margin-bottom:12px;
        text-align:center;
        font-size:18px;
        color:#01081F;
        font-weight:500;
        font-style:normal;
    }
    .no__event__button{
        width:280px;
    }
    .no__event{
        padding:24px;
        border-radius:10px;
        background:#F3F3F3;
    }
    .btn{
        display:flex;
        justify-content:center;
    }
    .upload__container{
        margin-top:17px;
    }
    .links__container{
        a{
            display:flex;
            font-size:14px;
            color:#0083FB;
            align-items:center;
        }
        a:first-child{
            margin-top:0;
            margin-bottom:5px;
        }
        .link{
            margin-left:12px;
        }
    }
    @media (max-width:1200px){
        .link-tag{
            width:100px;
        }
    }

    @media (max-width:992px){
        .task__detail__border, .task__detail__wrapper, .task__detail{
            display:block;
        }
        p{
            font-size:14px;
        }
        .link-tag{
            width:80%;
        }
        align-items:center;
        flex-direction:column;
        .bot-acc{
            position:relative;
        }
        .task__titling{
            width:100%;
        }
        .tag__container{
            width:100%;
        }
        .tasks__container{
            width:100%;
            margin-left:0;
            margin-top:32px;
        }
        .accordion-container:last-child{
            margin-bottom:0;
        }
        .task__title{
            display:flex;
            flex-direction:column;
        }
        .task__deadline{
            position:unset;
            right:unset;
            text-align:left;
            margin-top:10px;
        }
        @media(max-width:768px){
            .task__titling{
                width:90%;
            }
            .link-tag{
                width:35%;
            }
        }
        @media(max-width:575px){
            .link-tag{
                width:50%;
            }
        }
    }
`