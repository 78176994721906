import React from 'react';
import Task from "./Task"

const DashboardTask = () => {
    
    return (
        <Task />
    )
}

export default DashboardTask;